// This file is used to register the service worker to enable offline functionality
// and improve performance by caching assets.

// This code is based on Create React App's default service worker setup.

// Register the service worker
export function register(config) {
    if ('serviceWorker' in navigator) {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
      window.addEventListener('load', () => {
        const swRegistration = navigator.serviceWorker.ready.then(() => {
          return navigator.serviceWorker.register(swUrl);
        });
  
        swRegistration.then((registration) => {
          if (config && config.onUpdate) {
            registration.addEventListener('updatefound', () => {
              const installingWorker = registration.installing;
              if (installingWorker) {
                installingWorker.addEventListener('statechange', () => {
                  if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                      // New content is available; please refresh.
                      config.onUpdate(registration);
                    } else {
                      // Content is cached for offline use.
                      config.onSuccess(registration);
                    }
                  }
                });
              }
            });
          }
        });
      });
    }
  }
  
  // Unregister the service worker
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
      });
    }
  }
  