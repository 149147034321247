import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>Lutheran church located in Ufa, Russia. Our mission is to...</p>
    </div>
  );
};

export default About;
